import BorysGrinchenko from './borys-grinchenko-kyiv-university.png';
import InternsGo from './interns-go.png';
import Klitschko from './klitschko.png';
import Partner from './partner.jpg';
import RepetitorUa from './repetitor-ua.png';
import SavageKangaroo from './savage-kangaroo.png';
import StudPoint from './stud-point.png';
import ThinkGlobal from './think-global.png';
import Uniwork from './uniwork.png';

const logoPartners = [
  {
    id: 1,
    name: 'BorysGrinchenko',
    url: BorysGrinchenko,
  },
  {
    id: 2,
    name: 'InternsGo',
    url: InternsGo,
  },
  {
    id: 3,
    name: 'Klitschko',
    url: Klitschko,
  },
  {
    id: 4,
    name: 'Partner',
    url: Partner,
  },
  {
    id: 5,
    name: 'RepetitorUa',
    url: RepetitorUa,
  },
  {
    id: 6,
    name: 'SavageKangaroo',
    url: SavageKangaroo,
  },
  {
    id: 7,
    name: 'StudPoint',
    url: StudPoint,
  },
  {
    id: 8,
    name: 'ThinkGlobal',
    url: ThinkGlobal,
  },
  {
    id: 9,
    name: 'Uniwork',
    url: Uniwork,
  },
];

export default logoPartners;
