import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Speaking from '../img/reports/Speaking.net.jpg';
import LeadForPeace from '../img/reports/LeadForPeace.jpg';
import '../scss/hwch.scss';
import '../scss/curusel.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

export function CarouselBox() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const { t } = useTranslation();

  return (
    <Carousel className="b_g_c" activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <div className="carusel-cast-style">
          <div className="carusel-cast-style_item">
            <h2 className="h2-castem">{t('projects_slider.title')}</h2>
            <p className="main_txt">{t('projects_slider.info')}</p>
          </div>
          <div className="carusel-cast-style_img">
            <img className="slick_img" src={Speaking} alt="speaking net" />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carusel-cast-style">
          <div className=" carusel-cast-style_item">
            <h2 className="h2-castem">{t('projects_slider.title1')}</h2>
            <p className="main_txt">{t('projects_slider.info1')}</p>
          </div>
          <div className="carusel-cast-style_img">
            <img
              className="slick_img"
              src={LeadForPeace}
              alt="lead for peace"
            />
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}
