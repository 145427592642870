import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Marquee from 'react-fast-marquee';

import '../scss/aboutas.scss';

import Anastasia from '../img/creater/Anastasia.jpg';
import Ivan from '../img/creater/Ivan.JPG';
import Oleksandr from '../img/creater/Oleksandr.jpg';
import Vladislav from '../img/creater/Vladislav.JPG';

import AK from '../img/creater/AndreyKochkin.jpg';
import OD from '../img/creater/OleksandrDyachenko.jpg';
import RS from '../img/creater/RomanStarikov.jpg';
import TL from '../img/creater/TL.png';

import doc1 from '../img/doc1.svg';
import doc2 from '../img/doc2.svg';

function Creater() {
  const { t } = useTranslation();
  const creaters = t('about-as.creater', { returnObjects: true });
  const icons = [
    { img: Anastasia },
    { img: Vladislav },
    { img: Ivan },
    { img: Oleksandr },
    { img: AK },
    { img: TL },
    { img: OD },
    { img: RS },
  ];
  const creatersWithPhoto = creaters.map((elem, idx) => {
    return {
      ...elem,
      img: icons[idx].img,
    };
  });
  return creatersWithPhoto.map((el) => {
    return (
      <div className="create" key={el.id}>
        <img className="create_photo" src={el.img} alt="volunteer" />
        <span className="create_name">{el.name}</span>
        <span className="create_faq">{el.faq}</span>
      </div>
    );
  });
}

function OurDocuments() {
  const { t } = useTranslation();
  const documentsPdf = t('about-as.documents', { returnObjects: true });

  const iconsDoc = [{ img: doc2 }, { img: doc1 }, { img: doc2 }, { img: doc2 }];

  const createDoc = documentsPdf.map((elem, idx) => ({
    ...elem,
    img: iconsDoc[idx].img,
  }));

  return createDoc.map((el) => {
    return (
      <div className="documents" key={el.id}>
        <Link className="link" to={`/${el.id}`} download="pdf">
          <img className="documents_img" src={el.img} alt="img" />
          <div className="documents_description_wrapper">
            <p className="documents_title">{el.title}</p>
            <p className="documents_sub-title">{el.sub_title}</p>
          </div>
        </Link>
      </div>
    );
  });
}

export function AboutAs() {
  const { t } = useTranslation();

  const organizationsList = t('about-as.list', { returnObjects: true });

  return (
    <div className="about-as">
      <span className="about-as_title title">{t('about-as.title')}</span>
      <span className="about-as_sub-title">{t('about-as.sub-title')}</span>
      <div className="about-as_list">
        <div className="list">
          <ul>
            {organizationsList.map((el, idx) => (
              <li key={idx}>{el}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="about-as_modail-info">
        <div className="boolu" />
        <span>{t('about-as.modail')}</span>
        <div className="boolb" />
      </div>
      <p className="our-volunteers">{t('about-as.title-volunteers')}</p>
      <div className="about-as_creater">
        <Creater />
      </div>
      <div className="our-documents">
        <p className="our-documents_title">
          {t('about-as.our-documents_title')}
        </p>

        <Marquee
          speed={50}
          gradient={false}
          pauseOnClick={true}
          pauseOnHover={true}
        >
          <div className="wrapper-doc">
            <OurDocuments />
          </div>
        </Marquee>
      </div>
    </div>
  );
}
