import React, { useEffect, useState } from 'react';
import '../scss/footer.scss';
import AW from '../img/footer/AppStoreW.svg';
import GW from '../img/footer/GoogleStoreW.svg';
import { resize } from './header';
import telegram from '../img/footer/telegram.svg';
import linkedin from '../img/footer/linkedin.svg';
import facebook from '../img/footer/facebook.svg';
import AiesecLogo from '../img/aiesec/logo-blue2.jpg';

import { useTranslation } from 'react-i18next';

function WStor() {
  return (
    <div className="stor-case">
      <div className="stor-case_button">
        <a href="https://apps.apple.com/us/app/charity-hero/id1612487637">
          <img src={AW} alt="" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.magnetto.CharityHero&hl=ru&gl=US">
          <img src={GW} alt="" />
        </a>
      </div>
      <span className="stor-case_title" style={{ color: '#ffffff' }}>
        download app
      </span>
    </div>
  );
}

export function Footer() {
  const { t } = useTranslation();

  const [width, setWidth] = useState(false);

  useEffect(() => {
    resize(setWidth);

    window.addEventListener('resize', () => {
      resize(setWidth);
    });
  }, []);

  return (
    <div className="bg-footer">
      <div className="footer">
        <div className="footer_case">
          <img src={AiesecLogo} alt="aiesec" className="aiesec-logo" />
          <nav className="nav-footer">
            <a href="#needs-for-help" id="help">
              {t('header.nav.help')}
            </a>
            <a href="#partners" id="partner" className="nav-button">
              {t('header.nav.partner')}
            </a>
            <a href="#contacts" id="contact" className="nav-button">
              {t('header.nav.contact')}
            </a>
          </nav>
        </div>

        {width ? <WStor /> : ''}
        <div className="footer-box">
          <span className="footer-title">{t('footer.title')}</span>
          <div className="contacts-link" id="contacts">
            <a
              href="https://www.facebook.com/charityheroua"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="contacts-link_icon"
                src={facebook}
                alt="facebook"
              />
            </a>
            <a href="https://t.me/ukrainedf " target="_blank" rel="noreferrer">
              <img
                className="contacts-link_icon"
                src={telegram}
                alt="telegram"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/charityhero/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="contacts-link_icon"
                src={linkedin}
                alt="linkedin"
              />
            </a>
          </div>
        </div>
        <div className="footer_case">
          <div className="copyright">
            <p className="footer-marck">{t('footer.co')}</p>
          </div>
          {width ? '' : <WStor />}
        </div>
      </div>
    </div>
  );
}
