import { useEffect, useState } from 'react';
import '../scss/header.scss';
import { ModailCost } from './cost';
import { CostInternational } from './costInternational';
import Logo from '../img/header/logo.svg';
import LogoLenguage from '../img/header/language.svg';
import LogoLenguage1 from '../img/header/languageAct.svg';
import BAppStor from '../img/header/BAppStor.svg';
import BGStor from '../img/header/BGStor.svg';
import AiesecLogo from '../img/aiesec/logo-blue.png';
import map from '../img/bg-home.png';

import { useTranslation } from 'react-i18next';

export function scrollT(el) {
  el.scrollIntoView({ block: 'start', behavior: 'smooth' });
}

export function HeaderMini() {
  const [actB, setActB] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const CHB = Array.prototype.slice.call(
      document.querySelectorAll('#charityHero'),
    );
    CHB.forEach((el) => {
      el.addEventListener('click', () => {
        scrollT(document.querySelector('.of-charity-hero_title'));
        setActB(false);
      });
    });

    const HIW = Array.prototype.slice.call(
      document.querySelectorAll('#howItWork'),
    );
    HIW.forEach((el) => {
      el.addEventListener('click', () => {
        scrollT(document.querySelector('.how-its-worck'));
        setActB(false);
      });
    });

    const NUA = Array.prototype.slice.call(
      document.querySelectorAll('#neadUa'),
    );
    NUA.forEach((el) => {
      el.addEventListener('click', () => {
        scrollT(document.querySelector('.nnua'));
        setActB(false);
      });
    });

    const HFUA = Array.prototype.slice.call(
      document.querySelectorAll('#helpAFU'),
    );
    HFUA.forEach((el) => {
      el.addEventListener('click', () => {
        scrollT(document.querySelector('.help-f-of-ua'));
        setActB(false);
      });
    });

    const P = Array.prototype.slice.call(document.querySelectorAll('#partner'));
    P.forEach((el) => {
      el.addEventListener('click', () => {
        scrollT(document.querySelector('.carousel-partner'));
        setActB(false);
      });
    });

    const C = Array.prototype.slice.call(document.querySelectorAll('#contact'));
    C.forEach((el) => {
      el.addEventListener('click', () => {
        scrollT(document.querySelector('.contact-as'));
        setActB(false);
      });
    });
    const H = document.querySelector('#header');
    H.addEventListener('click', () => {
      scrollT(document.querySelector('.header'));
      scrollT(document.querySelector('.header-mini'));
      setActB(false);
    });
  });

  return (
    <div className="header-mini">
      <div className="logo-wrapper">
        <img className="header-mini_logo" src={Logo} alt="charity hero logo" />
        <img className="header-mini_logo" src={AiesecLogo} alt="aiesec logo" />
      </div>
      <div className="header-mini_nav-wrapper">
        <LanguageSwitcher />
        <div
          className="header-mini_open-button open-button"
          onClick={() => {
            setActB(!actB);
          }}
        >
          <div
            className={
              !actB ? 'open-button_top' : 'open-button_top open-button_top__act'
            }
          />
          <div
            className={
              !actB ? 'open-button_mid' : 'open-button_mid open-button_mid__act'
            }
          />
          <div
            className={
              !actB
                ? 'open-button_botton'
                : 'open-button_botton open-button_botton__act'
            }
          />
        </div>
      </div>
      <nav
        className={
          !actB ? 'header-mini_nav header-mini_nav__act' : 'header-mini_nav'
        }
      >
        <div className="modail-backgound" />
        <a href="#needs-for-help" className="button-mini-nav" id="neadUa">
          {t('header.nav.help')}
        </a>
        <a href="#partners" className="button-mini-nav" id="parner">
          {t('header.nav.partner')}
        </a>
        <a href="#contacts" className="button-mini-nav" id="contact">
          {t('header.nav.contact')}
        </a>
      </nav>
    </div>
  );
}

function NavButtonModail() {
  const [actH, setH] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const ButtonActH = document.querySelector('#actH');

    const actButton = (el, setUse) => {
      el.addEventListener('mouseenter', () => {
        setUse(true);
      });
      el.addEventListener('mouseleave', () => {
        setUse(false);
      });
    };

    actButton(ButtonActH, setH);
  }, []);

  return (
    <div className="nav_button-modul">
      <div className="modail-case " id="actH">
        <a
          href="#needs-for-help"
          className={!actH ? 'nav-button' : ' nav-button nav-button__act'}
          id="help"
          onFocus={() => {}}
        >
          {t('header.nav.help')}
        </a>
      </div>
      <a href="#partners" id="partner" className="nav-button">
        {t('header.nav.partner')}
      </a>
      <a href="#contacts" id="contact" className="nav-button">
        {t('header.nav.contact')}
      </a>
      &nbsp;
    </div>
  );
}

export function StorCase() {
  return (
    <div className="stor-case">
      <div className="stor-case_button">
        <a href="https://apps.apple.com/us/app/charity-hero/id1612487637">
          <img src={BAppStor} alt="" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.magnetto.CharityHero&hl=ru&gl=US">
          <img src={BGStor} alt="" />
        </a>
      </div>
      <span className="stor-case_title">download app</span>
    </div>
  );
}

export const resize = (set) => {
  if (window.innerWidth <= 620 && window.innerWidth >= 0) {
    set(true);
  } else {
    set(false);
  }
};

export function HomeContent(props) {
  const [, setWidth] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handler = () => resize(setWidth);

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return (
    <div className="home-content">
      <div className="bg-content">
        <img src={map} alt="map" />
      </div>
      <div className="home-content_title ">
        <span className="title-big">{t('home-content_title')}</span>
      </div>
      {props.location === 'ua' ? <ModailCost /> : <CostInternational />}
    </div>
  );
}

export function HeaderBig() {
  return (
    <div className="header">
      <div className="header_logo logo">
        <a href="#">
          <img
            className="header-mini_logo"
            src={Logo}
            alt="charity hero logo"
          />
          <img src={AiesecLogo} alt="aiesec" className="aiesec logo" />
        </a>
      </div>
      <div className="header_nav nav">
        <NavButtonModail />
      </div>
      <LanguageSwitcher />
    </div>
  );
}

export function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const [lngs] = useState({
    uk: { nativeName: 'uk' },
    en: { nativeName: 'en' },
    es: { nativeName: 'es' },
  });

  const [lngSwitcherHover, setLngSwitcherHover] = useState(false);

  const filteredLngs = Object.values(lngs).filter(
    (lng) => lng.nativeName !== i18n.resolvedLanguage,
  );

  return (
    <div
      className="leng-case"
      onMouseEnter={() => {
        setLngSwitcherHover(true);
      }}
      onMouseLeave={() => {
        setLngSwitcherHover(false);
      }}
    >
      <div className="leng-case_nav">
        <button className="nav-button">{i18n.resolvedLanguage}</button>
        {filteredLngs.map((lng) => (
          <button
            className="nav-button"
            key={lng.nativeName}
            onClick={() => i18n.changeLanguage(lng.nativeName)}
          >
            {lng.nativeName}
          </button>
        ))}
      </div>
      <img
        className="nav-button_logo"
        src={lngSwitcherHover ? LogoLenguage1 : LogoLenguage}
        alt=""
      />
    </div>
  );
}
